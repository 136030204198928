import BannerBelowNovelties from 'components/Blocks/Banners/Position/BannerBelowNovelties';
import Webline from 'components/Layout/Webline/Webline';
import { useBannerSuite } from 'connectors/banners/Banners';
import { BannerPositionEnumApi } from 'graphql/generated';

const BelowNoveltiesBannerCollection: FC = () => {
    const bannerData = useBannerSuite();

    const underNoveltyBannerData1 = bannerData[BannerPositionEnumApi.BelowNovelties_1Api];
    const underNoveltyBannerData2 = bannerData[BannerPositionEnumApi.BelowNovelties_2Api];
    const underNoveltyBannerData3 = bannerData[BannerPositionEnumApi.BelowNovelties_3Api];
    const underNoveltyBannerData4 = bannerData[BannerPositionEnumApi.BelowNovelties_4Api];

    return (
        <Webline>
            <div className="my-10">
                <div className="grid grid-cols-1 gap-x-5 md:grid md:grid-cols-[1fr_1fr] vl:grid-cols-[1fr_1fr_1fr_1fr]">
                    {underNoveltyBannerData1 !== null && <BannerBelowNovelties bannerData={underNoveltyBannerData1} />}
                    {underNoveltyBannerData2 !== null && <BannerBelowNovelties bannerData={underNoveltyBannerData2} />}
                    {underNoveltyBannerData3 !== null && <BannerBelowNovelties bannerData={underNoveltyBannerData3} />}
                    {underNoveltyBannerData4 !== null && <BannerBelowNovelties bannerData={underNoveltyBannerData4} />}
                </div>
            </div>
        </Webline>
    );
};

export default BelowNoveltiesBannerCollection;
