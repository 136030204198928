import { LocalizedDate } from 'components/Basic/Date/LocalizedDate';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Image from 'components/Basic/Image/Image';
import { ListedBlogArticleType } from 'types/blogArticle';

type BlogPreviewListProps = {
    items: ListedBlogArticleType[];
};

const BlogPreviewList: FC<BlogPreviewListProps> = ({ items }) => {
    const testIdentifier = 'blocks-blogpreview-main-';

    return (
        <div className="grid grid-cols-[repeat(2,_1fr)] justify-center gap-7 md:gap-14 xl:gap-y-14 xl:gap-x-32">
            {items.map((item, index) => (
                <article
                    className="flex w-full max-w-[400px] flex-col [grid-column-end:span_2] even:hidden sm:max-w-full sm:flex-row sm:items-start vl:[grid-column-end:span_1] vl:even:flex"
                    key={item.uuid}
                    data-testid={testIdentifier + index}
                >
                    <figure className="mb-2 flex sm:mb-0 sm:mr-7 sm:w-[200px] sm:shrink-0 md:mr-10">
                        <ExtendedNextLink
                            type="blogArticle"
                            href={item.link}
                            className="relative block w-full before:block before:pb-[100%] before:content-['']"
                        >
                            <Image
                                alt="alt"
                                src={item.image}
                                className="absolute bottom-0 top-0 left-0 right-0 overflow-hidden object-cover"
                                fill
                                sizes="(min-width: 480px) 20vw, 50vw"
                            />
                        </ExtendedNextLink>
                    </figure>
                    <div>
                        <a className="text-primary no-underline hover:no-underline" href={item.link}>
                            <h3 className="text-h5 font-bold text-inherit sm:line-clamp-2">{item.name}</h3>
                        </a>
                        <p className="mt-1 text-body font-normal leading-normal text-grayVeryDark line-clamp-4">
                            {item.perex}
                        </p>
                        <p className="mt-1 text-small font-medium text-grayDark">
                            <LocalizedDate date={item.publishDate} />
                        </p>
                    </div>
                </article>
            ))}
        </div>
    );
};

export default BlogPreviewList;
