import { useDomainConfig } from 'hooks/useDomainConfig';
import { useEffect, useState } from 'react';

type LocalizedDateProps = {
    date: string | Date;
};

export const LocalizedDate: FC<LocalizedDateProps> = ({ date }: LocalizedDateProps) => {
    const { defaultLocaleIdentifier } = useDomainConfig();
    const [dateString, setDateString] = useState(
        new Date(date).toLocaleDateString(defaultLocaleIdentifier, { timeZone: 'UTC' }),
    );

    useEffect(() => {
        setDateString(new Date(date).toLocaleDateString(defaultLocaleIdentifier));
    }, [date, defaultLocaleIdentifier]);

    return <>{dateString}</>;
};
